/* Tema CLARO */
.quill-light .ql-toolbar {
  background: #f9f9f9;
  border-color: #ddd;
}

.quill-light .ql-container {
  background: #fff;
  color: #000;
  border-color: #202020;
  border-radius: 8px;
}

.quill-light .ql-editor {
  min-height: 70px;
  font-size: 15px;
}

/* Tema ESCURO */
.quill-dark .ql-toolbar {
  background: #222;
  border-color: #ffffff;
}

.quill-dark .ql-container {
  /* background: #00000036; */
  color: #fff;
  border-color: #ffffff;
  border-radius: 8px;
}

.quill-dark .ql-editor {
  min-height: 70px;
  font-size: 15px;
}

.quill-dark .ql-picker {
  color: #fff;
}

.quill-dark .ql-stroke {
  stroke: #fff;
}

.quill-dark .ql-fill {
  fill: #fff;
}